.blog-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-page h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  .add-blog-button {
    display: block;
    margin: 0 auto 20px;
    background-color: #000 !important;
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
  }
  
  .add-blog-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .blog-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .blog-item {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .blog-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .see-more-button {
    display: block;
    margin: 10px auto;
    background-color: #007bff;
    color: white;
  }
  
  .see-more-button:hover {
    background-color: #0056b3;
  }
  
  .edit-button,
  .delete-button {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }
  
  .image-upload-section {
    margin-top: 20px;
    text-align: center;
  }
  
  .preview-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
  }
  
  .upload-label p {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #333;
  }
  