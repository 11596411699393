.home-page-information {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f0f2f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .home-page-information h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }
  
  .info-section {
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .update-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
    margin-top: 10px;
  }
  
  .update-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .slider-section {
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .slider-section h2 {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .slider-section p {
    margin-bottom: 10px;
    color: #666;
  }
  
  .images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .image-item {
    position: relative;
  }
  
  .slider-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }
  
  .add-images-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
    display: block;
    margin: 20px auto 0;
  }
  
  .add-images-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
  }
  
  .upload-label p {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #333;
  }
  
  .preview-images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 10px;
  }
  
  .preview-image-item {
    position: relative;
  }
  
  .preview-slider-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
  