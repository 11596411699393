.car-listing {
  padding: 20px;
}

.add-car-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.car-listing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.image-upload-section {
  margin-top: 20px;
}

.preview-image-container {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
  display: inline-block;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashed-container {
  border: 2px dashed #ddd;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}

.upload-label {
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
}

.upload-placeholder {
  color: #aaa;
}

.car-item {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin: 10px 0;
}

.car-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.car-image-container {
  position: relative;
  width: 100%;
  height: 200px;
}

.car-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
}

.additional-images {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px 10px 0 0;
}

.car-item:hover .additional-images {
  display: flex;
}

.additional-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 5px;
}

.car-details {
  padding: 20px;
}

.car-details h3 {
  margin: 0 0 10px;
  font-size: 1.5rem;
  color: #333;
}

.car-details p {
  margin: 5px 0;
  font-size: 1rem;
  color: #555;
}

.car-details p strong {
  color: #000;
}

.car-item-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.car-item-buttons .MuiButton-root {
  flex: 1;
  margin: 0 5px;
}
