.about-us {
  padding: 20px;
  background-color: #f0f2f5;
}

.about-us h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.editor-section {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 600px; /* Adjusted height */
}

.editor-section h2 {
  margin-bottom: 10px;
}

.quill-editor {
  flex-grow: 1;
  height: 350px;
  margin-top: 10px;
}

.save-button {
  background-color: #000000 !important;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease !important;
  font-size: 0.8rem !important;
}

.save-button:hover {
  background-color: #e67e22 !important;
  color: white !important;
}
