.categories-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .categories-page h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  .add-category-button {
    display: block;
    margin: 0 auto 20px;
    background-color: #000 !important;
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
  }
  
  .add-category-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .category-item {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .category-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .category-count {
    font-size: 1rem;
    color: #666;
  }
  
  .edit-button,
  .delete-button {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }
  