.manufacturers {
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .manufacturers h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.8rem;
    color: #333;
  }
  
  .add-manufacturer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .add-manufacturer-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 5px 10px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.7rem !important;
  }
  
  .add-manufacturer-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .search-field {
    flex-grow: 0;
    margin-right: 10px;
    border-radius: 20px !important;
    background-color: #f0f0f0 !important;
    font-size: 0.7rem !important;
    padding: 5px 10px !important;
    border: none !important;
    outline: none !important;
    width: 180px; /* Set a smaller width */
  }
  
  .search-field input {
    border: none !important;
    outline: none !important;
  }
  
  .search-field .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  
  .manufacturer-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .manufacturer-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
  }
  
  .manufacturer-item img {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  
  .manufacturer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .manufacturer-info h2 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  .drag-drop-box {
    border: 2px dashed #ddd;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
  }
  
  .drag-drop-box img.preview-image {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
    margin-bottom: 5px;
  }
  
  .drag-drop-box .upload-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .drag-drop-box .upload-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999;
  }
  