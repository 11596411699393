.sell-your-cars {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sell-your-cars h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  .car-sales-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .car-sale-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .car-sale-item:hover {
    background-color: #f1f1f1;
  }
  
  .car-sale-details {
    flex: 1;
  }
  
  .car-sale-details h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .car-sale-details p {
    margin: 5px 0;
    font-size: 0.9rem;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
  