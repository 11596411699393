.dashboard {
    padding: 10px;
    max-width: 1000px; /* Adjust the max-width as needed */
    margin: 0 auto; /* Center the dashboard */
  }
  
  .dashboard h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem; /* Reduced font size */
    color: #333;
  }
  
  .dashboard-stats {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Reduced min-width */
    gap: 10px; /* Reduced gap */
  }
  
  .stat-item {
    background: linear-gradient(135deg, #e67e22, #f39c12);
    border-radius: 10px; /* Reduced border-radius */
    padding: 10px; /* Reduced padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #fff;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .stat-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .stat-item h2 {
    font-size: 1.2rem; /* Reduced font size */
    margin-bottom: 5px; /* Reduced margin */
    color: #fff;
  }
  
  .stat-item p {
    font-size: 2rem; /* Reduced font size */
    font-weight: bold;
    color: #fff;
  }
  
  .stat-icon {
    font-size: 2.5rem; /* Reduced icon size */
    color: rgba(255, 255, 255, 0.8); /* Slightly transparent white for the icons */
    position: absolute;
    top: 10px; /* Reduced top position */
    right: 10px; /* Reduced right position */
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px; /* Reduced margin */
  }
  