.car-models {
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .car-models h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    color: #333;
  }
  
  .add-model {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .add-model-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 5px 10px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.7rem !important;
  }
  
  .add-model-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .search-field {
    flex-grow: 0;
    margin-right: 10px;
    border-radius: 20px !important;
    background-color: #f0f0f0 !important;
    font-size: 0.7rem !important;
    padding: 5px 10px !important;
    border: none !important;
    outline: none !important;
    width: 150px; /* Set a smaller width */
  }
  
  .search-field input {
    border: none !important;
    outline: none !important;
  }
  
  .search-field .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  
  .manufacturer-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .manufacturer-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .manufacturer-item img {
    max-width: 100%;
    max-height: 150px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .manufacturer-item h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  .model-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 8px;
    transition: background-color 0.3s ease;
  }
  
  .model-item p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  .model-item:hover {
    background-color: #f1f1f1;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
  }
  