.complex-structure {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .complex-structure h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  .complex-structure-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .values-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .value-item {
    flex: 1;
  }
  
  .update-button {
    align-self: center;
    background-color: #000000 !important;
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 1rem !important;
  }
  
  .update-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
  