.card {
    border-radius: 15px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    transition: transform 0.2s ease-in-out, max-height 0.3s ease !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    padding: 10px !important;
    max-height: 350px !important; /* Initial fixed height */
    overflow: hidden !important;
  }
  
  .card.show-more {
    max-height: none !important; /* Allow card to expand when show more is clicked */
  }
  
  .card:hover {
    transform: scale(1.02) !important;
  }
  
  .card-content {
    padding: 20px !important;
    flex-grow: 1 !important;
    overflow: hidden !important;
  }
  
  .card-title {
    font-weight: bold !important;
    margin-bottom: 10px !important;
  }
  
  .card-email,
  .card-phone {
    color: #888 !important;
    margin-bottom: 10px !important;
  }
  
  .card-message {
    margin-bottom: 20px !important;
    color: #555 !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    transition: max-height 0.3s ease !important;
    max-height: 4.5em !important; /* Control max height to show 3 lines of text */
  }
  
  .card-message.show-more {
    -webkit-line-clamp: unset !important;
    display: block !important;
    overflow: visible !important;
    max-height: none !important;
  }
  
  .card-actions {
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px 20px !important;
  }
  
  .card-actions button {
    flex: 1 !important;
    margin: 0 5px !important; /* Ensure there's space between buttons */
  }
  
  .show-more-button {
    background-color: #2196f3 !important;
    color: white !important;
    transition: background-color 0.2s ease-in-out !important;
  }
  
  .show-more-button:hover {
    background-color: #1976d2 !important;
  }
  
  .check-button {
    background-color: #4caf50 !important;
    color: white !important;
    transition: background-color 0.2s ease-in-out !important;
  }
  
  .check-button:hover {
    background-color: #45a045 !important;
  }
  
  .delete-button {
    background-color: #f44336 !important;
    color: white !important;
    transition: background-color 0.2s ease-in-out !important;
  }
  
  .delete-button:hover {
    background-color: #e53935 !important;
  }
  