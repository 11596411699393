.services {
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .services h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    color: #333;
  }
  
  .add-service {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .add-service-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 8px 16px !important;
    border-radius: 15px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
  }
  
  .add-service-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .service-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
  }
  
  .service-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .service-item img {
    max-width: 100%;
    max-height: 150px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .service-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .service-info h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-info p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  .drag-drop-box {
    border: 2px dashed #ddd;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    margin-top: 60px;
    border-radius: 10px;
  }
  
  .drag-drop-box img.preview-image {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .drag-drop-box .upload-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .drag-drop-box .upload-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  