.edit-car-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .edit-car-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .edit-car-form {
    display: flex;
    flex-direction: column;
  }
  
  .full-width-text-field {
    margin-bottom: 10px;
  }
  
  .image-upload-section {
    margin-top: 20px;
  }
  
  .dashed-box {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .preview-image-container {
    display: inline-block;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 5px;
    object-fit: contain;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid #ccc;
  }
  
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 5px;
  }
  
  .upload-label {
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
  }
  
  .upload-placeholder {
    color: #aaa;
  }
  
  .edit-car-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .editor-container {
    margin-bottom: 50px !important;
  }