.sidebar {
  width: 250px;
  padding: 10px;
  background-color: #000000;
  height: 100%;
  position: fixed;
  top: 60px; /* Adjust according to header height */
  left: 0;
  color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease;
  z-index: 1000;
}

.sidebar .MuiListItem-root {
  padding: 5px 10px;
}

.sidebar .MuiListItemIcon-root {
  min-width: 35px;
}

.sidebar .sidebar-icon {
  font-size: 1.2rem;
}

.sidebar .sidebar-text {
  font-size: 0.8rem;
}

.sidebar .active {
  background-color: #e67e22;
  border-radius: 20px;
}

.sidebar .active .MuiListItemIcon-root {
  color: white;
}

.sidebar .MuiListItem-root:hover {
  background-color: #34495e;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: 100vh;
    left: -100%; /* Hide sidebar off-screen */
    top: 0;
    bottom: 0;
    border-radius: 0;
    padding: 10px;
    top: 60px; /* Adjust according to header height */
  }

  .sidebar.visible {
    left: 0; /* Show sidebar on-screen */
  }

  .sidebar .menu-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
