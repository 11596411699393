.body-types {
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .body-types h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    color: #333;
  }
  
  .add-body-type {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .add-body-type-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 8px 16px !important;
    border-radius: 15px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
  }
  
  .add-body-type-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .body-type-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .body-type-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .body-type-item p {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
  }
  