.owners {
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .owners h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    color: #333;
  }
  
  .global-owner {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .global-owner h2 {
    margin-bottom: 20px;
  }
  
  .add-owner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .add-owner-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 8px 16px !important;
    border-radius: 15px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
  }
  
  .add-owner-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .update-global-owner-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 8px 16px !important;
    border-radius: 15px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
    margin-top: 20px;
  }
  
  .update-global-owner-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .set-global-owner-button {
    background-color: #000000 !important;
    color: white !important;
    padding: 8px 16px !important;
    border-radius: 15px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
    margin-top: 10px;
  }
  
  .set-global-owner-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .text-field {
    margin-bottom: 15px !important;
  }
  
  .owner-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
  }
  
  .owner-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  