/* App.css */

body, html, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  width: 100%;
  height: 60px;
  background-color: #000000;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: fixed;
  top: 0;
}

.header-title {
  color: white;
  margin-left: 20px;
}

.content {
  flex-grow: 1;
  padding: 20px;
  margin-top: 60px; /* Adjust according to header height */
  margin-left: 250px;
  transition: margin-left 0.3s;
}

.sidebar-open .content {
  margin-left: 80px;
}

@media (max-width: 1024px) {
  .content {
    margin-left: 0;
  }

  .sidebar-open .content {
    margin-left: 0;
  }
}
