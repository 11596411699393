/* src/pages/ContactUs/ContactUs.css */
.contact-us {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f8f9fa;
  }
  
  .contact-form-section {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-section .save-button {
    background-color: #000 !important;
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    font-size: 0.8rem !important;
    margin-top: 10px;
  }
  
  .contact-form-section .save-button:hover {
    background-color: #e67e22 !important;
    color: white !important;
  }
  
  .interested-users-section {
    margin-top: 20px;
  }
  
  .user-item {
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .user-item h6 {
    margin-bottom: 5px;
  }
  
  .user-item .delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    border-radius: 50%;
  }
  